body {
	margin: 0;
	font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	background-color: #303030;
	color: white;
	width: 100%;
	overflow-x: hidden !important;
}

code {
	font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

::-webkit-scrollbar {
	width: 5px;
}

::-webkit-scrollbar-track {
	background: #f1f1f1;
}

::-webkit-scrollbar-thumb {
	background: #888;
}

::-webkit-scrollbar-thumb:hover {
	background: #555;
}

input[type=text] {
	background-color: #252525;
	color: white
}

input[type=text]:focus,
input[type=text]:active {
	background-color: #313131;
	color: white
}

::placeholder {
	color: green
}

a {
	color: lightblue
}

a:hover {
	color: skyblue
}

.custom-list-item {
	border-color: grey !important;
}

.icons {
	width: 25px;
}

.text-right {
	text-align: right !important;
}